/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import config from "./util/Configuration.js";
import novemLogo from './novemLogo.svg';
import theNovemCloudLogo from './thenovemcloud.svg';
import Home from './pages/Home.js';
import CompanyBioPage from "./pages/CompanyBioPage.js";
import ContactPage from "./pages/ContactPage.js";
import DataSciencePage from "./pages/DataSciencePage.js";
import FullStackPage from "./pages/FullStackPage.js";
import KaizenPage from "./pages/Kaizen.js";
import TheNovemCloudPage from "./pages/theNovemCloud/TheNovemCloudPage.js"
import cs from "classnames";


import pkg from "../package.json";

import './App.scss';

const novemSitePawds = {
    home: {
        buttonName: 'Home',
        path: '/',
        exact: true,
        component: Home,
    },
    fullStack: {
        buttonName: 'Full Stack',
        path: '/full_stack',
        component: FullStackPage,
    },
    kaizen: {
        buttonName: "Agile Workflow",
        path: "/kaizen",
        component: KaizenPage,
    },
    dataScience: {
        buttonName: "Data Science",
        path: "/data_science",
        component: DataSciencePage,
    },
    companyBio: {
        buttonName: "Company Bio",
        path: "/company_bio",
        component: CompanyBioPage,
    },
    contactPage: {
        buttonName: 'Contact',
        path: '/contact',
        component: ContactPage,
    },
};
const theNovemCloudPawds = {
    home: {
        buttonName: 'Home',
        path: '/',
        exact: true,
        component: TheNovemCloudPage,
    },
    contactPage: {
        buttonName: 'Contact',
        path: '/contact',
        component: ContactPage,
    },
};

const pawdaryDict = {
    "novem-technology": Object.keys(novemSitePawds).map((itemkey) => novemSitePawds[itemkey]),
    "the-novem-cloud": Object.keys(theNovemCloudPawds).map((itemkey) => theNovemCloudPawds[itemkey]),
};

function beforeEnter(el) {
    el.scrollIntoView(true);
}

function App() {
    useEffect(() => {
        console.log(`UI_PROFILE: ${config.get('uiProfile')}`);
    });

    // Stuff that needs to be moved into generalize pawdaryDict
    const pawdKeys = Object.keys(pawdaryDict);
    const uiProfile = config.get('uiProfile');

    console.log(`(a84) uiProfile: ${uiProfile}`);
    // const isNovemSite = uiProfile === 'novem-site';
    const isTheNovemCloud = uiProfile === 'the-novem-cloud';
    const logoClass = isTheNovemCloud ? "thenovemcloudlogo" : "novemlogo";
    const logo = isTheNovemCloud ? theNovemCloudLogo : novemLogo;
    let pawdAryKey = "the-novem-cloud";
    if (pawdaryDict[uiProfile]) {
        pawdAryKey = uiProfile;
    }
    const pawdary = pawdaryDict[pawdAryKey];
    let navroutes = pawdary.map((item) => {
        const TheItem = item.component;
        const routeObj = (
            <Route
                path={item.path}
                exact={item.exact}
                key={item.buttonName}
            >
                {({ match }) => (
                    <CSSTransition
                            // eslint-disable-next-line react/jsx-indent-props
                        in={match != null}
                        timeout={750}
                        classNames="pageTransition"
                        onEnter={beforeEnter}
                        unmountOnExit
                    >
                        <TheItem />
                    </CSSTransition>
                )}
            </Route>
        );
        return routeObj;
    });
    let navlinks = pawdary.map((item) => (
        <NavLink
            className="pageButton"
            exact={item.exact}
            to={item.path}
            key={item.buttonName}
        >
            <div className="buttonContent">
                {item.buttonName}
            </div>
        </NavLink>
    ));

    return (
        <BrowserRouter>
            <div className={
                cs("novemsite", {[uiProfile]: true})}>
                <div className="navigator">
                    <div className="logoCell">
                        <img
                            src={logo}
                            className={logoClass}
                            alt="logo"
                        />
                    </div>
                    <div className="pageButtonsCell">
                        {navlinks}
                    </div>
                    <div className="copyrightCell">
                        <span className="copysym">
                        &copy;
                        </span>
                        <span>2024</span>
                        <br className="mobileOnly-inline" />
                        <span className="notMobileOnly-inline">&nbsp;</span>
                        <span>Novem Inc</span>
                        <div>
                            v.{pkg.version}
                        </div>
                    </div>
                </div>
                {/* column2: body
                <Route path="/" exact component={Home}/>
                <Route path="/company_bio" component={CompanyBioPage}/>
                <Route path="/contact" component={ContactPage}/>
                <Route path="/data_science" component={DataSciencePage}/>
                <Route path="/full_stack" component={FullStackPage}/>
                */}
                <div className="currentRouteContainer">
                    {navroutes}
                </div>
          </div>
      </BrowserRouter>

    );
}

export default App;
