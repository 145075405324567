import React from "react";
import "./HighlightCard.scss";
import cs from "classnames";

export default function HighlightCard(props) {
    const { title, mode, image, link, children, className } = props;

    return (
        <div className={cs("highlightCardContainer", className)}>
            <div className={cs("highlightCard", { [mode]: !!mode })}>
                <div className="highlightCardTitle">
                    <div className="titleContent">{title}</div>
                </div>
                <div className="highlightCardLink">
                    <a href={link}>
                        {link}
                    </a>
                </div>
                {children}
            </div>
        </div>
    );
}