import React from 'react';
import HighlightCard from '../../../components/HighlightCard.js';

export default function AMaineCompany(props) {
  const { mode = "sylvia" } = props;
  return (
    <HighlightCard key="a-maine-company" title="A Maine Company" className="aMaineCompanyCard">
      <p className="dontIndentFirstLine">
          <span className="novemInc">
          Novem Inc.&nbsp;
        </span>
        was founded in 2014 on historic Mt. Desert Island, and is
        now headquarted at our new offices in beautiful, 
        and also historic, Waterville, Maine. 

        We believe in investing in our community and bringing
        resources, jobs, and tech to Maine.

        We're currently renovating our physical offices located
        in a 200+ year-old brick building near downtown Waterville;
        but are a remote-first company successfully working with clients that
        range from startups to the top Fortune 100 companies.
      </p>
    </HighlightCard>
  );
}