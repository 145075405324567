class FeatureControl {
    /*  */
    constructor() {
        this._isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
    }

    isDevelopment() {
        return this._isDevelopment;
    }
}

const theFeatureController = new FeatureControl();

export default theFeatureController;
