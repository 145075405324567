import React, { useRef } from 'react';

export default function TheNovemCloudLogo(props) {
  const logoTargetRef = useRef(null);
  console.log("logoTargetRef", logoTargetRef);
  return (
    <div className="logoTarget" ref={logoTargetRef}>
      
    </div>
  )
}