import React from 'react';
import HighlightCard from '../../../components/HighlightCard.js';

export default function FullService(props) {
  const { mode = "sylvia" } = props;
  return (
    <HighlightCard key="full-service" className="fullServiceCard" mode="lighter" title="Software System Consulting">
      <p>
        Novem is a full service software development company 
        with roots in scientific data processing systems. 
        We specialize in cloud architecture, 
        data clarity, and business logic isolation.
        We also have decades of collective experience managing
        and building teams from the ground up, recruiting,
        mentoring, and leadership using agile methodology.
      </p>
      <p style={{textAlign: "center", margin: "15px 0"}}>
        <b>Treat your business like a science.</b>
      </p>
      <p>
        Build new tools! Integrate with new tech!
        Simplify your systems and reduce long term costs
        with the efficiency of data driven systems.
      </p>
      <p style={{fontStyle:"italic", textAlign: "center", fontSize: "110%"}}>
        Novem builds what your business goals require.
      </p>
    </HighlightCard>

  );
}