import React, {useState} from 'react';

import ModalImage, { Lightbox } from 'react-modal-image';


import ContactButton from "../../components/ContactButton.js";
import TheNovemCloudLogo from "../../logoLibrary/TheNovemCloudLogo.js";
import SylviaAccelerates from './highlightCards/SylviaAccelerates.js';
import FullService from './highlightCards/FullService.js';
import AMaineCompany from './highlightCards/AMaineCompany.js';
import Marquee from '../../components/Marquee.js';
import TechMarquee from '../../components/TechnologyMarquee.js';

import './TheNovemCloudPage.scss';

export default function TheNovemCloudPage(props) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const closeLightbox = () => {
    setOpenLightbox(false);
    console.log("closeLightbox");
  }
  const SharedSlide = () => (
      <div key="shared-slide" className="columnsVert">
        <div className="flexColumn">
          <AMaineCompany />
          
        </div>
        <div className="flexColumn">
          <img className="mainSequenceKaizenMarquee"
            onClick={() => setOpenLightbox(true)}
            src="devMainSequence.png" alt="Application Maturity Main Sequence" 
          />
          {/* <ModalImage 
            className="mainSequenceKaizenMarquee"
            alt="Application Maturity Main Sequence"
            small="devMainSequence.png"
            large="devMainSequence.png"
            hideDownload
            hideZoom={false}
            showRotate /> */}
        </div>
      </div>
    );

  return (
    <div className="filldiv">
      { openLightbox && <Lightbox 
        large="devMainSequence.png"
        alt="Application Maturity Main Sequence"
        hideDownload
        hideZoom
        showRotate={false}
        onClose={closeLightbox}
        />
      }  
      <h3 className="bodyTitle">The Novem Cloud</h3>
        <ContactButton />
        <div style={{marginTop: '2em'}} >
          <FullService />
          <Marquee duration={10000} showNavigation showBorder>
            <SharedSlide key="sharedSlide"/>
            <SylviaAccelerates key="sylviaAccelerates"/>
          </Marquee>

          <TechMarquee />

          <TheNovemCloudLogo />
      </div>
    </div>
  );
}
