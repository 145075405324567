import React from 'react';
import { Mail } from 'react-feather';
import './ContactButton.scss';
import { Link } from 'react-router-dom';

const ContactButton = () => {
    const element = (
        <Link to="contact">
            <button type="button" className="contactButton">
                <div className="contactButtonContentDiv">
                    <Mail />
                    <div className="contactButtonText">
                        Software Project Description Form
                    </div>
                </div>
            </button>
        </Link>
    );
    return element;
};

export default ContactButton;
