import get from "lodash/get";
import defaultsDeep from "lodash/defaultsDeep";
import config from "../config/novemsite_config.js";

let localconfig = {};
const DEBUG = false;
try {
    // this is not part of the package, but allows local configuration
    localconfig = require('../config/novemsite_config.local.js').default;
    if (DEBUG) console.log("C6 lconfig:", localconfig);
} catch (ex) {
    console.log("C5: Warning: No Local Configuration.");
}

const useConfig = defaultsDeep({}, localconfig, config);

if (DEBUG) console.log("C2: config", useConfig);
export default {
    get(key, def) {
        return get(useConfig, key, def);
    },
};
