import React from "react";
import HighlightCard from "../components/HighlightCard.js";

const stack = [
  <span> <b>Data Science</b>: SciPy, GDAL/OGR, Numpy</span>,
  <span> <b>Front End Frameworks</b>: React, Vue, Lit, Web Components</span>,
  <span> <b>Languages</b>: JavaScript, Python, Node.js, Go, C/C++</span>,
  <span> <b>Database</b>:  MongoDB, Postgres/PostGIS, S3</span>,
  <span> <b>Cloud Providers</b>: AWS, Azure, OpenStack, VMWare Private Clouds</span>,
  <span> <b>Containers and Virtualization</b>: Docker, Vagrant, VirtualBox</span>,
  <span> <b>Orchestration</b>: Docker Compose, Kubernetes, Terraform, Ansible</span>,
  <span><b>OS</b>:<br/> Linux (<i>Ubuntu, CENTOS/RHEL/Amazon Linux, Alpine</i>), Windows, Mac OSX</span>,
];


const stacksData = [
  {
    title: "Data Science",
    items: [
      "SciPy",
      "GDAL/OGR",
      "Numpy",
    ]
  },
  {
    title: 'Front End Frameworks',
    items: [
      "React",
      "Vue",
      "Lit",
      "Web Components",
    ]
  },
  { 
    title: 'Languages',
    items: [
      "JavaScript",
      "Python",
      "Node.js",
      "Go",
      "C/C++",
    ]
  },
  {
    title: 'Database',
    items: [
      "MongoDB",
      "Postgres/PostGIS",
      "S3",
    ]
  },
  {
    title: 'Cloud Providers',
    items: [
      "AWS",
      "Azure",
      "OpenStack",
      "VMWare Private Clouds",
    ]
  },
  {
    title: 'Containers and Virtualization',
    items: [
      "Docker",
      "Vagrant",
      "VirtualBox",
    ]
  },
  {
    title: 'Orchestration',
    items: [
      "Docker Compose",
      "Kubernetes",
      "Terraform",
      "Ansible",
    ]
  },
  {
    title: 'OS',
    items: [
      "Linux (Ubuntu, CENTOS/RHEL/Amazon Linux, Alpine)",
      "Windows",
      "Mac OSX",
    ]
  }
]

const modes = [
  'techStack',
  'techStackZod',
  'techIsh',
];

const newStackMl = stacksData.map((stack, ii) => {
  const modeIndex = ii % modes.length;
  const mode = modes[modeIndex];
  const { title, items } = stack;
  return (
    <HighlightCard key={title} mode={mode} title={title} className="stack">
      <div style={{textAlign: "center"}}>
        {items.join(", ")}
      </div>
    </HighlightCard>
  )
})

export { stacksData };
export default newStackMl;
