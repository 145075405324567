import React from 'react';
import "./Home.scss";
import ContactButton from "../components/ContactButton.js";

class Home extends React.Component {
    render() {
        const page = (
            <div className="filldiv">
                <h3 className="bodyTitle">
              Distributed System Architecture and Implementation<br/>
              Leadership and Process Optimization
                </h3>

                <ContactButton />

                <div className="pageBody">
                    <img
                        className="homeDiagRight"
                        alt="Not a simple distributed system."
                        title="Not a simple distributed system."
                        style={{width:'10%'}}
                        src="not-simple-distributed-system.png"
                  ></img>

                    <div>
                        <p>
                        Novem provides full stack software development
                        of distributed systems, including architecture, agile practices,
                        and team leadership.
                        We design and build complete
                        systems including services with RESTful and Structured APIs,
                        employee interfaces, public-facting web applications, SaaS,
                        and metrics-ready systems logging,
                        with decades experience in advanced data pipelines.
                        </p>
                        <p>
                        Systems will include CLI tools used to accelerate development,
                        for system administration, as well as integration with Devops/CI/CD
                        pipelines, exploiting state of the art cloud orchestration
                        following the <i>Infrastructure as Code</i> concept.
                        </p>

                        <img
                        className="homeDiagLeft"
                        alt="Distributed System with Message Bus"
                        title="Distributed System with Message Bus"
                        src="complex-message-bus.png"/>
                        
                        <p>
                        We work with our clients to understand what you need and desire, "must haves"
                        and "nice to haves",  
                        and turn that into a <b>specification</b> we share with you for review.
                        From that we will create a lightweight design and
                        development plan with a
                        concrete <b>implementation schedule</b> which
                        includes all client feedback
                        and provides a transparent framework for the work to be done,
                        indluding onging feedback from the client during development. 
                        This makes everyone happy!
                        </p>

                        <p>
                            During development an agile process is used, involving the client in the
                            iterative development cycle. A continually improving demo will be visible
                            as the system is developed into a viable product. Client feedback is 
                            continually integrated into the development process. Final results will
                            be a viable product, operational documentation and tools, and a 
                            roadmap for future evolution of the product as understood at the time.
                        </p>
                  </div>

                    <p>
                    In addition to new system development,
                    Novem Inc understands how to modernize legacy systems!
                    Microservices gradually add to and replace
                    legacy features while the system remains
                    running <i>without feature setbacks</i>.

                    We understand your systems are mission critical.
</p>
                    <p style={{ marginTop: '2em' }}>
                    For more information, click "Contact Us" above, or email questions to: <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#110;&#111;&#118;&#101;&#109;&#46;&#116;&#101;&#99;&#104;&#110;&#111;&#108;&#111;&#103;&#121;">&#105;&#110;&#102;&#111;&#64;&#110;&#111;&#118;&#101;&#109;&#46;&#116;&#101;&#99;&#104;&#110;&#111;&#108;&#111;&#103;&#121;</a>
                    .
</p>

                    <div style={{ marginTop: '4em', fontStyle: 'italic' }}>
                    "The open source Novem Recipe Machine added greater efficiency to our data analysis processes and
                    gave us greater value when seeking funding from investors. Novem was there with sound advice and
                    reasonable rates."
                        <div style={{ marginLeft: '100px' }}> -- Bridget Adams - HydroBio ARS, COO </div>
                  </div>
            </div>
            </div>
        );
        return page;
    }
}

export default Home;
