import React from 'react';
import HighlightCard from '../../../components/HighlightCard.js';

export default function SylviaAccelerates(props) {
  const { mode = "sylvia" } = props;
  return (
    <HighlightCard key="sylvia-accelerates" mode={mode} title="Sylvia&trade;" className="sylviaAcceleratesCard">
      <p  className="desktopInline">
        Novem accelerates the development process
        using Sylvia&trade; and Trunk&trade; Components,
        Novem's distributed system framework.
        Sylvia includes tools for IaC, RESTful and WebSockets services,
        data reduction workers, and a simple, safe, and controlled way to integrate
        with additional cloud services.
      </p> <p className="desktopInline">
        We create custom front ends for consumers, customers, and internal
        staff. Create new front ends in the framework of your choice, 
        or start with one of Novem Inc's existing React and Lit
        front end boilerplates.
        </p> <p>
        </p> <p className="desktopInline">
        Sylvia plays well with all other component frameworks because it's at 
        a higher level,
        the level of your whole business, with all its parts. Every business is complex,
        but we avoid the pitfalls of complexity with <i>data flow clarity</i>.
      </p> <p className="desktopInline">
        With Sylvia and Trunk Components we
        compose scalable systems component by component.
        You get to start small but never get painted into a corner.  
        Your system grows with your business. 
        You'll learn even more about your business by knowing more about your data.
      </p>
    </HighlightCard>
  );
}