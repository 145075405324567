import React from 'react';
import "./Home.scss";
import ContactButton from "../components/ContactButton.js";

class Home extends React.Component {
    render() {
        const page = (
          <div className="filldiv">
                <h3 className="bodyTitle">Novem Company Bio</h3>
                <ContactButton />
                <div className="pageBody bodyRightJustify">


                    <p className="dontIndentFirstLine">
                        <span className="novemInc">
                        Novem Inc.&nbsp;
                    </span>
                    was founded in 2014 on historic Mt. Desert island in Maine,
                    performing remote software development for startups and large
                    corporations across the country.
                    In 2016 we relocated to Oakland, ME,
                    in the beautiful Belgrade Lakes region,
                    and opened an office in Waterville.
                    We develop RESTful APIs, browser-based client software,
                    SaaS, and data processing pipelines and can consult on all
                    computing issues regarding network, data, application
                    architectures. We can also help you with Agile and Process Optimization.
                </p>
                    <p className="dontIndentFirstLine">

                        <img
                            className="pageImage"
                            title="Craig Allen, Novem President"
                            alt="Craig Allen, Novem President"
                            src="/craig.JPG"
                      ></img>

                    <b>Craig Allen</b>, Systems Architect & Senior Software Engineer,
                    is a talented distributed systems designer and analyst experienced at
                    leading teams through the design and implementation processes creating
                    distributed systems
                    since the 90s.
                    He has specialized in large
                    scale data processing pipelines in entertainment,
                    commerce, and science at organizations including
                    Intel,
                    NASA Ames Research Center,
                    University of California at Berkeley, Gemini Observatory,
                    Sierra Online, AT&T, the DMA, Bain and Company, and others.
                    </p>
                    <p>
                    Craig was formally trained in logic at UC Berkeley while working
                    for NASA and UCB Astronomy doing scientific data processing and 
                    visualizations software, holds a BA in Philosophy from UCB. With 
                    his direction Novem designs system that 
                    are <i>valid</i>, <i>sound</i>, and <i>coherent</i>.
                    For hobby time Craig also enjoys time in nature with his
                    family, reading, and dogs, cats, and all animals.
                </p>
                    <img
                        className="pageImage pageImageLeft" src="jenny.JPG"
                        alt="Jenny Allen, Novem Inc. Business Manager"
                        title="Jenny Allen, Novem Inc. Business Manager"
                  ></img>
                    <p className="dontIndentFirstLine">
                    <b>Jenny Allen</b>, the Novem Business Manager,
                    has a record of developing successful campaigns,
                    strategies, and solutions for professionals and organizations in business and politics.
                    Before joining forces with Novem, she was an AmeriCorps Program Manager in Hilo, HI,
                    and campaign manager for political projects and candidates.
                    She is recognized for her ability to build relationships with key personnel
                    in your organization and natural and easy issue resolution.
                    Areas of expertise include: Business Management, Events Coordination,
                    Brand Strategy Implementation, Public Relations, and Executive Administration.
                    She received her BA in Business with an Economics minor
                    from the University of Hawaii at Hilo in 2010.
                    </p>
                    <p className="dontIndentFirstLine">
                    <b>Collaboration Program</b>:
                    We also have a network of
                    collaborators in app development,
                    data science and operations expertise.
                    This includes senior software engineers as well as 
                    reasonably priced apprentices and junior developers as
                    appropriate to the project. Operational support is also 
                    available through Novem Inc and our partners.
                    Flat rate support or per ticket resolution is
                    available.
                    Contact us to 
                    join our collaboration network. 
                    <br/> <br/> 
                    <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#108;&#108;&#97;&#98;&#64;&#110;&#111;&#118;&#101;&#109;&#46;&#116;&#101;&#99;&#104;&#110;&#111;&#108;&#111;&#103;&#121;">&#99;&#111;&#108;&#108;&#97;&#98;&#64;&#110;&#111;&#118;&#101;&#109;&#46;&#116;&#101;&#99;&#104;&#110;&#111;&#108;&#111;&#103;&#121;</a>
                  </p>
                    <br clear="all" />
                    <div className="dontIndentFirstLine">
                        <b>Past and Current Novem Clients:</b>
                        <ul>
                            <li><span><a href="https://www.bp.com">bp</a></span></li>
                            <li><span><a href="https://bain.com">Bain and Co</a></span></li>
                            <li><span><a href="https://www.intel.com">Intel Corp</a></span></li>
                            <li><span><a href="https://www.dma.mil/">The DMA</a></span></li>
                            <li><span><a href="https://www.mednax.com/">MEDNAX, Inc. (now <i>Pediatrix Medical Group</i>)</a></span></li>
                            <li><span><a href="https://discover.digitalglobe.com/">DigitalGlobe</a></span></li>
                            <li><span><a href="https://www.comsearch.org/">ComSearch</a></span></li>
                            <li><span><a href="https://www.businesswire.com/news/home/20170508006021/en/The-Climate-Corporation-Acquires-HydroBio-Accelerates-Delivery-of-Data-Driven-Water-Management-Insights-for-More-Farmers-across-Globe">HydroBio ARS</a></span></li>
                            <li>... and more!</li>
                            

                      </ul>

                        <b>Examples of Previous Employers:</b>
                        <ul>
                            <li><span><a href="https://www.gemini.edu/">Gemini International Observatory</a></span></li>
                            <li><span><a href="https://en.wikipedia.org/wiki/Spinner_(website)">Spinner.com</a></span></li>
                            <li><span><a href="https://www.corp.att.com/worldwide/">AT&T</a></span></li>
                            <li><span><a href="https://en.wikipedia.org/wiki/AOL">AOL</a></span></li>
                            <li>
                                <span>
                                    <a href="https://en.wikipedia.org/wiki/Sierra_Entertainment">Sierra Online</a>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <a href="https://www.filfre.net/2018/02/the-sierra-network/">&nbsp;&nbsp;&nbsp;&nbsp;
                                    (The Sierra Network)
                                    </a>
                                </span>
                            </li>
                            <li><span><a href="https://astro.berkeley.edu/">UC Berkeley Astronomy Dept</a></span></li>
                            <li><span><a href="https://www.nasa.gov/ames">NASA Ames Research Center</a></span></li>
                      </ul>

                  </div>

            </div>
            </div>
        );
        return page;
    }
}

export default Home;
