/*
    Configuration.
    This file should contain configurations that are default for production
    but not contain any secrets or urls or any data that should not be exposed
    in git.

    Site specific settings can be set in a local config not checked into
    git named `novem_site_config.local.js`. It has the same 'shape' as this file
    exporting the config as default. A deepdefaults copy where the `.local`
    copy 'wins'. For development, delete settings you don't need and override
    settings you want. For production leave all the settings, in order to
    be immune to changes in the repo affecting production.

*/

// REACT_APP_UI_PROFILE = novem-site || the-novem-cloud
const config = {
    serverName: "TrunkPeer",
    submitContactUrl: process.env.REACT_APP_CONTACT_SUBMIT_URL ? process.env.REACT_APP_CONTACT_SUBMIT_URL : "CONTACT_SUBMIT_URL_HERE",
    uiProfile: process.env.REACT_APP_UI_PROFILE ? process.env.REACT_APP_UI_PROFILE : "the-novem-cloud",
};

export default config;
