import React from 'react';
import "./Home.scss";
import ContactButton from "../components/ContactButton.js";


class Home extends React.Component {
    render() {
        const page = (
          <div className="filldiv">
                <h3 className="bodyTitle">Data Science</h3>

                <ContactButton />
                <div className="pageBody bodyRightJustify">
                    <img
                        className="pageImage"
                        alt="Diagram of SaaS Data Analysis System using the Recipe Model"
                        style={{ border: 'solid black 1px' }} src="recipe_model-V3-200.jpg"
                  ></img>
                    <p>
                    Our Senior Software Engineer, System Architect,
                    and Novem Inc. founder, Craig Allen, brings decades of experience
                    working with scientists to implement and optimize both integrated
                    and automated processing systems.
                    Craig has spent over fifteen years of his career
                    specifically developing automated reduction systems
                    and data pipelines, having led the design and implementation
                    of the Gemini Recipe System at Gemini Observatory, and also
                    working at Nasa Ames Research Center and the UCB Astronomy
                    Deptartment.
                </p>
                    <p>
                    The "Gemini Recipe System"
                    was developed at Gemini Observatory since 2004 and is
                    still in use at Gemini facilities for
                    processing data in real time to aid observing and
                    by researchers with Gemini data, in order to perform
                    interactive and automated data analysis.

                    Novem Inc has extended the recipe system beyond astrophysics
                    starting in 2014 by Novem Inc. with our fork,
                    the Novem Recipe Machine.
                </p>
                    <p>
                    Often data processing software is developed
                    as proof of concept,
                    to validate the theory behind the processing.
                    This means it is common for initial pipelines 
                    to be scripts or
                    script-like, linear, and not very flexible as software
                    systems, i.e. not
                    lending themselves to easy integration
                    with other steps in
                    the production versions of the processing.
                </p>
                    <p>
                    While these programs perform the needed scientific processes,
                    they do not scale nor lend themselves to automation.
                    What is needed is the the ability to monitor
                    and control these processes in a coherent way.
                    The Recipe System is not only just such an automated control system,
                    but a way of refectoring code to use it. The "Recipe"
                    at the heart of any reduction process maintains the sequential character
                    of the naive implementations, making it easy to 
                    refactor such scripts into well behaved Recipe Primitives.
                </p>
                    <p>
                    In other cases, when working in a system which is already
                    mature and scalable, it is more
                    appropriate to focus on tools that adapt to the
                    local system philosophy and perform seemlessly within
                    that environment.  Novem Inc. applies both approaches,
                    based on our customer's particular needs.
                </p>
                    <p>
                    Craig's experience with sophisticated front ends comes from
                    the need to access and visualize data that comes out of
                    the analysis pipeline, as well as early years making
                    game software in Windows.
                    Around 2006 he begane implementing all 
                    User Interface components ("front end")
                    as browser based interfaces rather than native applications.
                    This model became known as "SaaS", or Web Applications.
                </p>

            </div>
                <img className="pageImageMobile" alt="Diagram of SaaS Data Analysis System using the Recipe Model" style={{ border: 'solid black 1px' }} src="recipe_model-V3-200.jpg" />

            </div>
        );
        return page;
    }
}

export default Home;
