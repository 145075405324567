import React, { useState } from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import './Marquee.scss';

const SlideNavigation = (props) => {
  const { instanceRef, currentSlide, numPages, selectSlide } = props;
  const indexList = [];
  for (let i =0 ; i < numPages; i++) {
    indexList.push(i);
  }
  console.log("(m86) instanceRef", instanceRef, currentSlide);
  const jsx = (
    <div className="dots">
      {indexList.map((idx) => {
        
        return (
          <button
            key={idx}
            onClick={() => selectSlide(idx)}
            className={"dot" + (currentSlide === idx ? " active" : "")}
          ></button>
        )
      })}
    </div>
  );
  return jsx;
}

export default function Marqee(props) {
  const { 
    duration = 2000,
    transitDuration = 500,
    easing = (t) => t,
    showNavigation = false,
    showBorder = false,
  } = props;
  const animation = { duration: transitDuration, transitDuration, easing, }
  const [sliderObj, setSliderObj] = useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      renderMode: "performance",
      rtl: false, // right-to-left
      slideChanged(slider) {
        console.log('slide changed', slider.track.details.rel);
        setCurrentSlide(slider.track.details.rel);
      },
      loop: true,
      drag: true,
      defaultAnimation: animation,
      centered: true,
      slides: {
        origin: 'center',
      },
      // created(s) {
      //   s.moveToIdx(5, true, animation)
      // },
      // updated(s) {
      //   s.moveToIdx(s.track.details.abs + 5, true, animation)
      // },
      // animationEnded(s) {
      //   //s.moveToIdx(s.track.details.abs + 5, true, animation)
      // },
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, duration);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  );
  console.log("(m65) instanceRef", instanceRef.current);
  const selectSlide = (idx) => {
    if (!instanceRef.current) return;
    instanceRef.current.moveToIdx(idx);
    // setCurrentSlide(idx);
  };
  const frames = props.children.map((item,i) => {

    console.log('item: ', {i, item});
    const elem = <div key={item.key} style={{ alignSelf: 'center' }} className="keen-slider__slide verticalCenterSelf">
      {item}
    </div>;
    return elem;
  });
  return (
    <>
      { showBorder && <hr/>}
      <div ref={sliderRef} className="keen-slider">
        {frames}
      </div>
      { showNavigation && <SlideNavigation 
        instanceRef={instanceRef}
        currentSlide={currentSlide} 
        numPages={props.children.length}
        selectSlide={selectSlide}
      />}
      { showBorder && <hr/> }
    </>
  )
}
