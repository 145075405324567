import React from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import techStackJsx, { stacksData } from '../data/techStacks.js';
import Marquee from './Marquee.js';
import './TechMarquee.scss';

export default function TechnologyMarqee() {
  
  const stackFrames = techStackJsx.map((item,i) => {
    const elem = <div key={item.key} className="verticalCenterSelf">
      {item}
    </div>;
    return elem;
  });

  return (
    <Marquee className="techMarquee"
      showNavigation
      duration={3000}
      transitDuration={700}
    >
      {stackFrames}
    </Marquee>
  )
}

