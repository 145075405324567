/*

%%FUTURE %%TODO
%%REFACTOR: this form contains a generalizable form building mechanism.

*/
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import "./Home.scss";
import './ContactPage.scss';

import classNames from 'classnames';
import loReduce from "lodash/reduce";

import React from 'react';
import he from "he";

import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import { Editor } from 'primereact/editor';

import { parsePhoneNumber, ParseError } from 'libphonenumber-js/max';
import faker from 'faker';
import featureController from '../util/FeatureControl.js';
import config from '../util/Configuration.js';

// just for testing/development, handle better?

/* @@REFACTOR: All this form building stuff will be going in it's own
*/

// validators
const DEBUG = true;

function isNotEmpty(value) {
    if (value === null || value === undefined) {
        return {
            valid: false,
            reason: 'Value Cannot Be Empty.',
        };
    }
    if (typeof (value) === "string") {
        const tval = value.trim();
        const valid = tval.length > 0;
        const reason = valid ? "Value is non-empty." : "Value cannot be empty";
        return { valid, reason };
    }
    return {
        valid: false,
        reason: `unknown type: '${typeof value}'`,
    };
}

function isValidPhoneNumber(value) {
    try {
        const phoneNumber = parsePhoneNumber(value, 'US');
        if (DEBUG) console.log("CP38: phoneNumber", phoneNumber);
        // if got  this far, then it parsed, but for some reason
        // is not checked for number of digits (has area code)
        const rawNumber = phoneNumber.nationalNumber;
        const valid = phoneNumber.isPossible();// rawNumber.length === 10;
        const reason = valid ? "Found US Phone Number" : "Not A Valid US Number";
        return { valid, reason };
    } catch (error) {
        if (error instanceof ParseError) {
            console.error("cp57: Phone Number Parse Error", error.message);
        }
        return {
            valid: false,
            reason: "Not a valid US Phone Number",
            message: error.message,
        };
    }
}

function isValidEmail(email) {
    const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) {
        return {
            valid: false,
            reason: "No Email Provided",
        };
    }

    if (email.length > 256) {
        return {
            valid: false,
            reason: "Email Too Long",
        };
    }

    if (!tester.test(email)) {
        return {
            valid: false,
            reason: "Not a validly Formatted Email",
        };
    }

    // Further checking of some things regex can't handle
    let [account, address] = email.split('@');
    if (account.length > 64) {
        return {
            valid: false,
            reason: "Not a validly Formatted Email",
        };
    }

    let domainParts = address.split('.');
    if (domainParts.some((part) => part.length > 63)) {
        return {
            valid: false,
            reason: "Not a validly Formatted Email",
        };
    }
    return true;
}

function getItems() {
    const mt = "&#109;&#97;&#105;&#108;&#116;&#111;&#58;";
    const nt = "&#64;&#110;&#111;&#118;&#101;&#109;&#46;&#116;&#101;&#99;&#104;&#110;&#111;&#108;&#111;&#103;&#121;";
    // const gm = "&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;"

    let items = [
        {
            label: "Email:",
            name: "info",
        },
    // {
    //     label: "Services, Front Ends and Apps:",
    //     name: "fullstack"
    // },
    // {
    //     label: "Data Analysis Pipeines:",
    //     name: "recipes",
    // },
    // {
    //     label: ".com Address:",
    //     name: "novemcge",
    //     zone: gm,
    // }
    ];
    items = items.map((item) => {
        const thet = item.zone ? item.zone : nt;
        item.token = he.decode(item.name + thet);
        item.tokenView = he.encode(item.token, {
            encodeEverything: true,
            decimal: true,
        });
        item.mtToken = he.decode(mt) + item.token;
        return item;
    });
    return items;
}

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.DEBUG = true;

        if (this.DEBUG) console.log("get url", config.get("submitContactUrl"));
        if (this.DEBUG) console.log(`ContactPage constructor: DEBUG=${this.DEBUG}`);
        const items = getItems();
        const contactItems = items.map((item) => {
            const retv = (
                <div key={item.name} className="linkCell" aria-label="Link Section">
                    <div className="linkTitle">
                        {item.label}
                  </div>
                    <div className="linkLink">
                        <a href={item.mtToken} dangerouslySetInnerHTML={{ __html: item.tokenView }} />
                  </div>
              </div>
            );

            return retv;
        });
        this.contactItems = contactItems;

        this.formValKeys = [
            "contactLabel",
            "wholeName", "company", "phoneNumber",
            "email", "description", "contactMessage"];

        this.formValDefs = {
            contactLabel: {
                type: 'message',
                message: <p style={{ opacity: 0.7, margin: '1em' }}>
                        Tell us how to get in touch with you.
                    </p>,
            },
            wholeName: {
                pretty: "Name",
                validate: isNotEmpty,
            },
            company: {
                pretty: "Company",
                // validate: isNotEmpty,
            },
            phoneNumber: {
                pretty: "Phone Number",
                validate: isValidPhoneNumber,
            },
            email: {
                pretty: "Email",
                validate: isValidEmail,
            },
            description: {
                type: 'texteditor',
                height: "8em",
                validate: isNotEmpty,
                pretty: (
                    <div style={{ fontSize: "150%" }}>
                        <div>
                            <p>
                                Tell us about yourself. 
                            </p>
                        </div>
                        <ol>
                            <li>
                                What business are you in?
                            </li>
                            <li>
                                What kind of data does your business deal with?
                            </li>
                            <li>
                                What do you want the software we develop for you to accomplish?
                            </li>
                            <li>
                                What would make you love the software we develop for you?
                            </li>
                            <li>
                                What role do your legacy system play in your plans?
                            </li>
                        </ol>
                        Please add anything else you think is important! Thanks.
                    </div>
                ),

            },
            contactMessage: {
                type: 'message',
                message: <p style={{ fontSize: "80%", opacity: 0.7, margin: '1em' }}>
                        By submitting this form using the button below you are
                        giving permission for Novem Inc to contact you about the
                        software related issues you have mentioned above. We will
                        contact you for a free consultation about your project.
                    </p>,
            },
            timeZone: {
                pretty: "Time Zone",
                validate: () => ({ valid: true, reason: "Time Zone" }),
            },
        };

        let storeWholeName   = sessionStorage.getItem("wholeName");
        let storeCompany     = sessionStorage.getItem("company");
        let storePhoneNumber = sessionStorage.getItem("phoneNumber");
        let storeEmail       = sessionStorage.getItem("email");
        let storeDescription = sessionStorage.getItem("description");
        let storeSavedDoc = sessionStorage.getItem("savedDoc");

        const wholeName = storeWholeName ? storeWholeName : "";
        const company = storeCompany ? storeCompany : "";
        const phoneNumber = storePhoneNumber ? storePhoneNumber : "";
        const email = storeEmail ? storeEmail : "";
        const description = storeDescription ? storeDescription : "";

        const requestSubmitted = sessionStorage.getItem("requestSubmitted");
        if (requestSubmitted) {
            this.state = {
                wholeName,
                company,
                phoneNumber,
                email,
                description,
                savedDoc: storeSavedDoc,
                showForm: false,
                notice: {
                    status: "complete",
                    subStatus: "contactSaved",
                    message: <div>
                                Contact Request Saved.
                                    <br />
                                <div className="details">
                                    <div>
                                        Requests may not
                                        be immediately reviewed.
                                    </div>
                                    <div>
                                        Please allow a few days for
                                        a <span style={{ fontSize: '110%', fontWeight: 700, fontFamily: `"Lucida Console", Monaco, monospace` }}>
                                        Novem
                                        </span>
                                        {' '}
                                        representative to contact you.
                                    </div>
                                    <div>
                                        <i>Thanks for contacting us!</i>
                                        {" "}
                                        <span>We look forward to
                                        working with you. </span>
                                    </div>
                                </div>
                            </div>,
                },
                notices: this.formValKeys.reduce((ac, itemKey) => {
                    ac[itemKey] = ''; // `notice for ${itemKey}`;
                    return ac;
                }, {}),
            };
        } else {
            this.state = {
                wholeName,
                company,
                phoneNumber,
                email,
                description,
                topMessage: <div>
                    <div>We're full stack software developers developing
                    distributed software systems.
                    
                    We make customer-facing 
                    software, office software,
                    and all the backend services and worker processes
                    needed for mission critical data processing.
                    <i>Treat your business like a science.</i>
                    </div>
                </div>,
                showForm: true, // turn off to deploy other changes
                liveValidate: false,
                notice: {
                    status: "normal",
                    substatus: "normal",
                    message: null,
                },
                savedDoc: null,
                notices: this.formValKeys.reduce((ac, itemKey) => {
                    ac[itemKey] = ''; // `notice for ${itemKey}`;
                    return ac;
                }, {}),
            };
        }
    } 
    handleKey(event) {
            console.log("event",event);
            if (event.key === "v" && event.altKey === true && event.ctrlKey === true) {
                const fakeFirstName = faker.name.firstName();
                const fakeLastName = faker.name.lastName();
                const fakeName = `${fakeFirstName} Test ${fakeLastName}`;
                const fakeCompany = faker.company.companyName();
        
                const fakeInet = faker.internet.domainName();
                const fakeEmail = `${fakeFirstName.toLowerCase()}.${fakeLastName.toLowerCase()}@${fakeInet}`;
                
                const fakePhoneNumber =  faker.phone.phoneNumber();
                const fakeDescription = `<p>${faker.lorem.sentences()}</p>`;
                
                this.setState({
                    wholeName: fakeName,
                    company: fakeCompany,
                    phoneNumber: fakePhoneNumber, 
                    email: fakeEmail,
                    showForm: true,
                    description: fakeDescription,
                })
        
            }
    }

    componentDidMount () {
        this.keyHandler = (event) => this.handleKey(event)
        document.addEventListener("keydown", this.keyHandler)
        console.log("mounted");
    }

    componentDidUpdate() {
        const wholeName = this.state.wholeName;
        const company = this.state.company;
        const phoneNumber = this.state.phoneNumber;
        const email = this.state.email;
        const description = this.state.description;
        sessionStorage.setItem("wholeName", wholeName);
        sessionStorage.setItem("company", company);
        sessionStorage.setItem("phoneNumber", phoneNumber);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("description", description);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyHandler);
        console.log("unmounting");
    }

    updateForm(change) {
        console.log('CP125: probably change the state', change, this.state);
        const changeKeys = Object.keys(change);
        console.log('CP282: ', changeKeys)
        for (let changeKey of changeKeys) {
            sessionStorage.setItem(changeKey, change[changeKey])
        }
        this.setState(change);

        if (this.state.liveValidate) this.validateData(change);
    }

    validateData(data) {
        const validities = [];
        const details = {};
        const notices = {};
        for (const key of Object.keys(data)) {
            // @@DEVNOTE: valDef does get updated!
            const valDef = this.formValDefs[key];
            const value = data[key];
            let validity = valDef.validate ? valDef.validate(value) : {
                valid: true, // if no validate function anything goes
                reason: 'validation not requred',
            };
            const validityName = valDef.validate ? valDef.validate.name : "requirement";
            if (typeof validity === "boolean") {
                // function can return boolean or object with 'valid' and 'reason'
                validity = {
                    valid: validity,
                };
            }
            const result = validity.valid ? "passed" : "failed";
            const defaultReason = `${result} ${validityName}`;
            // if validate function returns object, it should have reason internally
            if (!validity.reason) {
                validity.reason = defaultReason;
            }
            validities.push(validity);
            // details only for failures
            if (!validity.valid) {
                details[key] = validity;
                valDef.notice = validity.reason;
                valDef.status = "invalid";
            } else {
                valDef.notice = "good";
                valDef.status = "valid";
            }

            notices[key] = valDef.notice;
        }
        if (DEBUG) console.log("CP265: notices", notices);
        this.setState({ notices });
        // go through all even if hitting false to report all errors at once
        const valid = validities.reduce((allValid, validity) => allValid && validity.valid, true);

        // putting messages in the props of the valDef... react doesn't watch members

        return { valid, details };
    }

    submissionData() {
        const localeOptions = Intl.DateTimeFormat().resolvedOptions();
        const timeZone = localeOptions.timeZone;
        return {
            wholeName: this.state.wholeName,
            company: this.state.company,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            description: this.state.description,
            timeZone,
        };
    }

    async submitForm() {
        const url = config.get('submitContactUrl');
        const data = this.submissionData();

        const { valid, details } = this.validateData(data);
        this.forceUpdate();
        if (DEBUG) console.log("cp299: valid?", valid, details);
        if (DEBUG) console.log("cp300 doc", data);
        this.setState({ liveValidate: true });

        const parms = new URLSearchParams(window.location.search);
        const parmkeys = parms.keys();
        let variables = {};
        for (let parmkey of parmkeys) {
            variables[parmkey] = parms.get(parmkey);
        }

        if (!valid) return; // early, don't make request if locally invalid

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    doc: {
                        _ndoc: {
                            doctype: "contactRequest",
                        },
                        scope: {
                            host: window.location.hostname,
                            path: window.location.pathname,
                            variables,
                        },
                        ...data,
                    },
                }),
            });
            const msg = await response.json();
            const savedDoc = msg.doc;
            if (DEBUG) console.log("cp338: Post Contact Request Response", msg, savedDoc);

            const notice = {
                status: "complete",
                subStatus: "contactSaved",
                message: <div>
                            Contact Request Saved.
                                <br />
                            <div className="details">
                                <div>
                                    <i>Thanks for contacting us!</i>
                                    {" "}
                                    <span>We look forward to
                                    working with you. </span>
                                </div>
                                <div style={{fontSize:"75%"}}>
                                    Requests may not
                                    be immediately reviewed.
                                </div>
                                <div style={{fontSize:"75%"}}>
                                    Please allow a few days for
                                    a 
                                    Novem
                                    representative to contact you.
                                </div>
                                
                            </div>
                         </div>,
            };
            const showForm = false;
            const requestSubmitted = true;
            sessionStorage.setItem("notice", notice);
            sessionStorage.setItem("requestSubmitted", requestSubmitted);
            sessionStorage.setItem("savedDoc", savedDoc);
            this.setState({
                savedDoc,
                notice,
                showForm,
            });
            const el = document.querySelectorAll('.novemsite')[0];
            if (el) el.scrollTo(0, 0);
        } catch (e) {
            console.log("Error with Request (CP340):", e.message);
            this.setState({
                notice: {
                    status: "error",
                    substatus: "badResponse",
                    message: <span>
                      Error Contacting Host.
                        <br />
                      {' '}
                      Please try later or contact via email given below.
                             </span>,
                },
            });
            const el = document.querySelectorAll('.novemsite')[0];
            if (el) el.scrollTo(0, 0);
        }
    }

    render() {
    // const formValKeys = Object.keys(this.formValDefs);
        const isDev = featureController.isDevelopment();
        // DEBUG OVERRIDE this.showForm = isDev ? isDev : false;
        const { validateCount } = this.state;
        const formEls = this.formValKeys.map((valKey) => {
            // console.log("valKey", valKey);
            const def = this.formValDefs[valKey];
            def.key = valKey;
            let {
                key, height, type, pretty, values,
            } = def;
            if (!type) {
                type = "textinput";
            }
            let element;
            switch (type) {
            case "message":
                element = (
                  <div className="messageContainer" key={key}>
                      <div className="messageContent">
                          {def.message}
                        </div>
                    </div>
                );
                break;
            case "textinput":
                element = (
                    <div className="valueContainer" key={key}>
                        <span className="p-float-label">
                            <InputText
                                id={key}
                                className="inputStandard"
                                value={this.state[key]}
                                onChange={(ev) => this.updateForm({
                                    [key]: ev.target.value,
                                })}
                          />
                            <label htmlFor={key}>
                            {def.pretty}
                                <span className={`field-notice notice-status-${def.status}`}>
                                    {this.state.notices[key]}
                              </span>
                          </label>
                      </span>
                  </div>
                );
                break;
            case "texteditor":
                element = (
                  <div className="valueContainer" key={key}>
                      <div className="valueTitle">{pretty}</div>
                      <Editor
                          id={key}
                          style={{ height }}
                          className="formBox"
                          rows={5}
                            // cols={30}
                          value={this.state.description}
                          onTextChange={(e) => this.updateForm(
                                {
                                    [key]: e.htmlValue,
                                },
                            )}
                          autoResize
                        />
                    </div>
                );
                break;
            case "textbox":
                element = (
                  <div className="valueContainer" key={key}>
                      <div className="valueTitle">{pretty}</div>
                      <InputTextarea
                          id={key}
                          className="formBox"
                          rows={5}
                            // cols={30}
                          value={this.state.value}
                          onChange={(e) => this.updateForm({ [key]: e.target.value })}
                          autoResize
                        />
                    </div>
                );
                break;

            case "selection":
                element = (
                  <div className="valueContainer" key={key}>
                      <div className="valueTitle">{pretty}</div>
                      <div>
                          <SelectButton
                              inputStandard
                              value={this.state[key]}
                              options={values}
                              onChange={(ev) => this.updateForm({
                                    [key]: ev.value,
                                })}
                            />
                        </div>
                    </div>
                );
                break;

            default:
                element = (
                  <div className="nyimpl" key={key}>
                      "
                        {pretty}
                      " not yet implemented
                    </div>
                );
            }

            return element;
        });
        formEls.push(<div className="righteningContainer" key="submitButton">
          <Button
              className="centerControl"

              label="Contact Novem - Submit"
              onClick={(ev) => this.submitForm()}
            />
                     </div>);

        let topMessage;
        if (this.state.notice.status === 'normal') {
            topMessage = this.state.topMessage;
        } else {
            topMessage = this.state.notice.message;
        }
        const outClassNames = classNames({
            contactForm: true,
            filldiv: true,
            [this.state.notice.status]: true,
        });
        // Main Page Body
        const page = (
            <div
            className={outClassNames} data-validate-count={
                    this.state.validateCount
                }
          > 
            {' '}
            <h3 className="bodyTitle compact-title">
                  Describe Your Project
                </h3>
                <h2 className="topMessage">{topMessage}</h2>
                <div className="container pageBody contactPageBody">
                    <div className="inner-container">
                        {
                            this.state.showForm
                                ? formEls
                                : null
                        }

                        <div className="contactItems">
                            {this.contactItems}
                      </div>
                        <div className="bloglink linkCell">
                            <div className="linkTitle">
                            Engineering Blog:
                            </div>
                            <div className="linkLink">
                                <a href="http://blog.novem.technology/" target="_blank" rel="noopener noreferrer">
                                MCWCAM Blog
                                </a>
                          </div>
                      </div>
                        <div className="bloglink linkCell">

                            <div className="linkTitle">
                            LinkedIn:
                            </div>
                            <div className="linkLink">
                                <a href="    https://www.linkedin.com/in/craigallen-novemtechnology/" target="_blank" rel="noopener noreferrer">
                                Craig Allen LinkedIn Profile
                                </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
        return page;
    }
}

export default ContactPage;
