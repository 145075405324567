import React from 'react';
import ContactButton from "../components/ContactButton.js";


class Home extends React.Component {
    render() {
        const page = (
          <div className="filldiv">
                <h3 className="bodyTitle">Iterative Workflow, Agile, Scrum and Kanban</h3>

                <ContactButton />
                <div className="pageBody bodyRightJustify">
                <img
                        className="mainSequenceKaizenHuge"
                        alt="Application Maturity Main Sequence"
                        style={{ border: 'solid black 1px' }} src="devMainSequence.png"></img>
                    <p>
                    In the 90s a series of iterative and incremental software development 
                    methodologies began to develop which would be collected into a spectrum
                    of agile development practices. Formative to this was the development
                    in the late 80s of the Capability Maturity Model at the Software 
                    Engineering Institute at Canegie Mellon University, stemming from 
                    persistent problems the DOD with software development subcontractors 
                    being severely behind schedule, over budget, or ending in failure, producing
                    nothing usable. 
                    </p>
                    <p>
                    The answer in short was adopting iterative, retrospective, practices. The
                    waterfall approach needed to be abandoned, and also, every software domain
                    requires particular practices and workflow custom fit to its particular
                    information landscape.  While the particular practices must therefore be 
                    architected not unlike a software system, they did produce a way to review 
                    software development teams maturity and capability level.
                    </p>
                    
                    <p><b>The Five Capability-Maturity Levels</b>: 
                    <img
                        className="mainSequenceKaizenNormal"
                        alt="Application Maturity Main Sequence"
                        style={{ border: 'solid black 1px' }} src="devMainSequence.png"></img>
                        <ol>
                            <li>Initial</li>
                            <li>Repeatable</li>
                            <li>Defined</li>
                            <li>Capable</li>
                            <li>Efficient</li>
                        </ol>
                    </p>
                    <p>
                    A team, given some software problem starts at the <i>initial</i> level. It's
                    possible to make working software at this level, through heroism. The programmers
                    can write a program, can install it on their machine. At the next level the
                    programmer(s) can repeat installation, updating systems, setting them up again.
                    The next level adds definition, which helps the practices to be repeatable by 
                    more people, and more efficiently. It also leads to increased location, as this 
                    simplifies definitions and increases repeatability. At level 4, we add metrics, which will
                    be possible to collect from the defined processes of level 3. Finally, and by the 
                    time of production, these defintions and repetitions are optimized, and made efficient.
                    </p>
                    <p>
                    Software projects follow a same trajectory, begining with nearly pure discovery
                    processes, which are committed to repositories, documented, and expanded. This processes
                    is what agile practices, roles, and ceremonies are meant to enable, breathing the
                    life of all five maturity levels into complex, mature, software projects.
                    </p>

            </div>
                <img className="pageImageMobile"
                     alt="Application Maturity Main Sequence"
                     style={{ border: 'solid black 1px' }} src="devMainSequence.png" 
                />

            </div>
        );
        return page;
    }
}

export default Home;
