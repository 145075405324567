import React from 'react';
import "./Home.scss";
import ContactButton from "../components/ContactButton.js";
import "./FullStackPage.scss";

class Home extends React.Component {
    render() {
        const stack = [
            <div className="nt-stack-item"> <b>Data Science</b>: SciPy, GDAL/OGR, Numpy</div>,
            <div className="nt-stack-item"> <b>Front End Frameworks</b> React, Vue, Lit, Web Components</div>,
            <div className="nt-stack-item"> <b>Languages</b>: JavaScript, Python, Node.js, Go, C/C++</div>,
            <div className="nt-stack-item"> <b>Database</b>:  MongoDB, Postgres/PostGIS, S3</div>,
            <div className="nt-stack-item"> <b>Cloud Providers</b>: AWS, Azure, OpenStack, VMWare Private Clouds</div>,
            <div className="nt-stack-item"> <b>Containers and Virtualization</b>: Docker, Vagrant, VirtualBox</div>,
            <div className="nt-stack-item"> <b>Orchestration</b>: Docker Compose, Kubernetes, Terraform, Ansible</div>,
            <div className="nt-stack-item"><b>OS</b>: Linux (<i>Ubuntu, CENTOS/RHEL/Amazon Linux, Alpine</i>), Windows, Mac OSX</div>,
        ];

        const stacklist = stack.map((item) => <li key={item}><span>{item}</span></li>);


        const page = (
          <div className="filldiv">
                <h3 className="bodyTitle">Full Stack Development</h3>
                <ContactButton />
                <div className="bodyRightJustify">
                    <img className="cubeImg" alt="Cube Puzzle" src="cubeman-sm.png" />
                    <p className="noIndentOnDesktop">
                    Novem Inc. develops distributed
                    applications for internal or public use, including
                    high traffic public sites, such as SaaS platforms,
                    data processing pipelines, integrating components
                    and other business or science tools.
                    Servers always provide RESTful, structured, APIs and often
                    WebSocket connections.
                    Our systems also include command line tools
                    for CI/CD build and infrastructure scripts, system administration,
                    and troubleshooting.
                    For front end interfaces we create
                    responsive modern JavaScript applications
                    using web component type systems such as React, Vue,
                    or Polymer, in ECMA6+, with testing and well formed
                    projects.
                    We rely on libraries such as D3 and Matplotlib for
                    advanced visualizations.
                    </p>
                    <p>
                    We have also worked with many more tools and
                    technologies than our go-to stack. If you have a technology chosen,
                    we will both find experts to help and become experts. We've learned
                    new languages just to use new stacks, and we know how to do
                    professional research on new tools and languages.
                    </p>
                    <p>
                    We use Python, Numpy, GDAL/OGR
                    and SciPy for data analysis.
                    We use Node.js to ingegrate web services
                    and to provide API endpoints (sometimes Python). 
                    General purpose tools
                    will be made in the appropriate language for your
                    particular stack, internal practices, and preferences.
                    We integrate to systems in any language or tech stack,
                    as the ability to choose the best language and system
                    for a particular problem is a natural benefit of 
                    of distributed systems, if done wisely.
                    Novem Inc does prefer open source systems 
                    and the <i>Unix Philosophy</i>.
                    </p>
                    <p>
                    All development is
                    object oriented and scalable. Work includes: planning,
                    agile development, and integration with internal teams.
                    Cloud IT and other services are available. We will
                    also help in referal to Novem Inc partners
                    specializing in long term CICD operations
                    and end user support if you want long term help
                    operating your system.
                    </p>
                    <ul>
                        {stacklist}
                  </ul>
                    <p />
            </div>
            </div>
        );
        return page;
    }
}

export default Home;
